<template>
  <div>

    <b-alert
      v-if="$route.query.appointment_id && $route.query.doctor_id"
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Perjanjian <strong>{{ $route.query.doctor_name }}</strong>
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push(`/appointment/detail/${$route.query.appointment_id}`)"
      >Detail</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Buat Resep Obat'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'prescriptions'"
            />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/prescriptions/Form.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      form: {
        date: new Date().toISOString().substr(0, 10),
        patient_id: this.$route.query.patient_id ?? '',
        patient_name: this.$route.query.patient_name ?? '',
        doctor_id: this.$route.query.doctor_id ?? '',
        appointment_id: this.$route.query.appointment_id ?? '',
        doctor_name: "",
        history: "",
        notes: "",
        advice: "",
        medicine: "",
      },
    };
  },

  components: {
    Card,
    Form,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Resep Obat", route: "/prescriptions/list" },
      { title: "Buat Resep Obat" },
    ]);
  },
};
</script>

<style>
</style>